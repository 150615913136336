
// MY THEME COLORS 
$light-blue: #19B574;
$secondary: #ffffff;
$brand-blue: #00415d;
$white: #fff;
$white-light: #DFD9F6;
$brand-mute: #000000;
$headings-color: #ffffff;
$light-gray: #fafafa;
$gray: #adaaaa;
$colorpink: rgb(204, 19, 164)

