.login-txt {
    max-width   : 80%;
    margin-left : auto;
    margin-right: 0;
}

.login-btns a {
    background: #FF7856;
    padding   : 10px 32px;
    color     : #fff;
    border    : 1px solid #FF7856;
    font-size : 18px;

    &:hover {
        color: $white;
    }
}

.login-btns a:last-child {
    background : transparent;
    border     : 1px solid;
    margin-left: 10px;

    &:hover {
        background-color: #FF7856;
        color           : $white;
        border          : 1px solid #FF7856;
    }
}

.btn-inline .btn img {
    width: 16px;
}

.login-txt {
    .btn {
        border : 1px solid #C4C4C4;
        padding: 10px 30px;
        @include border-radius(10px);

        &:first-child {
            margin-right: 10px;
        }
    }
}

.alternative-link {
    text-align: center;
    position  : relative;
    z-index   : 1;

    &::after {
        position  : absolute;
        background: #C4C4C4;
        height    : 1px;
        width     : 100%;
        left      : 0;
        top       : 50%;
        transform : translateY(-50%);
        content   : "";
        z-index   : -1;
    }

    span {
        background   : #f5f5f5;
        padding      : 10px;
        border-radius: 50%;
        display      : inline-block;
        width        : 60px;
        height       : 60px;
        line-height  : 35px;
        color        : $headings-color;
    }
}

.forget-pass {
    color         : $primary;
    text-transform: capitalize;
}

.form-group {
    position     : relative;
    z-index      : 1;
    margin-bottom: 15px;

    label {
        @extend .fw-6;
        margin-bottom: 5px;
    }

    input {
        padding-left: 30px;
        color       : #000000;
        border      : 2px solid #C4C4C4;
        background  : transparent;

        &::placeholder {
            color: #000000;
        }
    }

    &::before {
        position   : absolute;
        font-family: fontawesome;
        left       : 10px;
        top        : 72%;
        transform  : translateY(-50%);
        color      : #000000;
    }

    &.form-pass::before {
        content: "\f023";
    }

    &.form-name::before {
        content: "\f007";
    }

    &.form-email::before {
        content: "\f0e0";
    }

    &.form-eye::before {
        content: "\f070";
        left   : auto;
        right  : 10px;
    }

    &.form-code input {
        width      : 20%;
        text-align : center;
        padding    : 20px 15px;
        color      : #222;
        font-weight: 700;
        font-size  : 22px;
    }
}

.form-check {
    .form-check-input {
        width  : 5px;
        height : 5px;
        padding: 7px;
    }
}

.login-txt {
    h3 {
        font-size: 34px;
        @extend .fw-7;
        margin-bottom: 30px;
    }
}